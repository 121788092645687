/* Typography */

body {
  font-size: 1.2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}
h1{
  font-size: 4.5rem;
  line-height: 4.5rem;
}
p,
a,
li {
  font-family: "Assistant", sans-serif;
}

a {
  transition: color 0.3s ease-in-out;
}


/* General */

figure {
  margin: 0 0 0.8rem;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eee;
}

figure img {
  width: 100%;
}

.p-bottom {
  padding-bottom: 60px;
}

.container {
  max-width: 960px;
}

.site-header {
  transition: all 0.8s ease-in-out;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-brand:focus, {
  color: #ffffff;
  text-decoration: underline;
  opacity: 0.8;
}

.navbar-light .navbar-brand:hover {
  color: #ffffff;
  opacity: 0.8;
}

.bg-blue {
  background: rgba(20, 90, 180, 0.8);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.8s ease-in-out;
}

.btn-primary {
  padding: 0.675rem 1rem;
  color: #093873;
  background: #ffd94c;
  border-color: #ffd94c;
  transition: all 0.3s ease-in-out;
}

.btn-primary:hover {
  color: #052d5e;
  background-color: #f7cb2c;
  border-color: #f7cb2c;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
}

.circle-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
}

.circle-wrap {
  z-index: 0;
  right: 0;
  top: 0;
  -webkit-transform: translate(15%, 40%);
  -ms-transform: translate(15%, 40%);
  transform: translate(15%, 40%);
}

.circle-wrap .circle {
  -webkit-animation: 5s cubic-bezier(0.36, 0.11, 0.89, 0.32) 0s normal none infinite running scaleIn;
  animation: 5s cubic-bezier(0.36, 0.11, 0.89, 0.32) 0s normal none infinite running scaleIn;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  height: 150px;
  margin-left: -75px;
  margin-top: -75px;
  opacity: 0;
  position: absolute;
  width: 150px;
}

@-webkit-keyframes scaleIn {
  from {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
  }
  to {
      -webkit-transform: scale(6, 6);
      transform: scale(6, 6);
      opacity: 0;
  }
}

@keyframes scaleIn {
  from {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      opacity: 1;
  }
  to {
      -webkit-transform: scale(6, 6);
      transform: scale(6, 6);
      opacity: 0;
  }
}
/* Header */
header {
  position: fixed;
  width: 100%;
  z-index: 2;
}
.site-header .row .col {
  padding-left: 0;
  padding-right: 0;
}
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}
.navbar-light a.navbar-brand {
  font-family: "Playfair Display", serif;
  color: #ffffff;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #dedede;
  text-decoration: underline;
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover{
  color: #dedede;
}
hr{
  max-width: 680px;
}
/* Main */
main .container {
  padding-top: 220px;
  padding-bottom: 180px;
  position: relative;
  z-index: 1;
}

main .container .row .col-md-6 {
  z-index: 1;
}
main,
main.homepage,
main.aboutpage,
main.works,
main.contactspage {
  background-position: center center;
  background-size: cover;
  background-attachment: scroll;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  min-height: 690px;
}
main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 109, 214, 0.8);
  transition: all 0.3s ease 0s;
}
main .border-bottom  {
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-bottom: 0px solid #ffffff !important;
}
main h1,
main p {
  color: #e8f6ff;
}
main p {
  font-size: 1em;
}
main a{
  color:#ffffff;
  text-decoration: underline;
}
main a:hover {
  color: rgb(232, 246, 255);
  text-decoration: underline;
  opacity: 0.8;
}
section .container {
  padding-top: 120px;
  padding-bottom: 120px;
}

#next-section p,
#next-section li {
  color: #64696e;
}

#next-section strong {
  color: #454545;
}

#next-section .date p {
  font-size: 1rem;
  font-style: italic;
  color: #417bc8;
}
#next-section .website p{
  font-size: 0.8rem;
  color: #333;
  font-style: italic;
}
#next-section .website a{
  font-size: 0.8rem;
  font-style: italic;
  color: #417bc8;

}
section#studies{
  background: #dfedff;
  position: relative;
}
section#studies p{
  color: #666666;
}
section#studies h5{
  color: #222222;
}
section#studies .date p{
  margin-bottom: 0;
  font-size: 1rem;
  font-style: italic;
  color: #417bc8; 
}
/* Portfolio page */
.portfolio {
  background: #ffffff;
  margin-bottom: 40px;
  box-shadow: 0 0 30px rgba(80, 80, 80, 0.1);
  border: 1px solid #f0f0f0;
  border-radius: 6px;
}
.portfolio .desc {
  padding: 10px 15px 20px;
}
.portfolio h3 {
  font-size: 1.2rem;
  line-height: 1.2;
  margin-bottom: 0;
  color: #336cb6;
}
.portfolio p {
  margin-bottom: 0;
  font-size: 16px;
}
.quote{
  font-size: 14px;
  color: #666666;
}
.quote span{
  color: #333333;
  font-style: italic;
}
footer {
  position: relative;
  background: #f2f2f2;
  padding-top: 50px;
  padding-bottom: 50px;
}
a.fs15{
  font-size: 15px;
}
footer a{
  color: rgb(41, 100, 179);
}
footer a:hover{
  color:rgb(22, 76, 148);
}
footer .footer-copyright{
  font-size: 14px;
}
.social img{
  width: 36px;
  margin: 0 4px;
  opacity: 0.9;
  border-radius:50%;
  transition: all 0.2s ease;
}
.social:hover{
  cursor: pointer;
}
.social:hover img{
  opacity: 1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
.border-top{
  z-index: 0;
  position: absolute;
  left: 0;
  top: -1px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  border-top: 0px solid #ffffff !important;
}
@media screen and (min-width: 1921px) {
  .border-bottom,
  .border-top {
      display: none;
  }
}